'use client';
import { SidebarFavoritesEntity } from '@entities/sidebar';
import { ItemsBlock } from '@widgets/mobile/list-cards-wrappers/list-cards-wrappers';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';

import { Illustration } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './cards.module.scss';

export const FavoritesCardsMobile = observer(() => {
  const shop = useStore().shop;
  const favorite = useStore().favorite;
  const router = useRouter();

  if (!favorite.favoriteIds.length) {
    return (
      <SidebarFavoritesEntity
        id={'favorite-empty'}
        className={styles.empty}
        topSlot={<SidebarFavoritesEntity.Image variant={'favorite-no-items'} />}
        middleSlot={
          <SidebarFavoritesEntity.Info
            info={{
              title: 'Favorites are empty',
              subtitle: 'Add items using the heart️ button on the item card!',
            }}
          />
        }
        bottomSlot={
          <SidebarFavoritesEntity.ActionButton
            text={'Open categories'}
            onClick={() => router.push('/categories')}
            variant={'primary'}
            iconRight={
              <Illustration
                id={'sidebar-fav-icon'}
                spriteName={'icons'}
                name={'square-arrow-right'}
              />
            }
          />
        }
      />
    );
  }

  return (
    <ItemsBlock
      items={shop.cards.filter(item =>
        favorite.favoriteIds.includes(item.realName),
      )}
    />
  );
});
